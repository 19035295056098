import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import Link from '@mui/material/Link';
//import Button from '@mui/material/Button';
import Home from "./pages/home";
import Away from "./pages/away";
import Login from "./pages/login";
import Logout from "./pages/logout";
import Profile from "./pages/profile";
import Register from "./pages/register";
import Inside from "./pages/inside";
import Team from "./pages/team";
import Teams from "./pages/teams";
import Invite from "./pages/invite";
import Account from "./pages/account";
import Notfound from "./pages/notfound";
import Moodboard from "./pages/moodboard";
import About from "./pages/about";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import Recover from "./pages/recover";

export default function App() {


  return (
    <BrowserRouter>
     <Navbar

     />
      <Routes>
        <Route path="/" element={<Home />}>

        </Route>
        <Route path="away" element={<Away />} />
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="profile" element={<Profile />} />
        <Route path="register" element={<Register />} />
        <Route path="team" element={<Team />} />
        <Route path="team/:id" element={<Team />} />
        <Route path="invite/:id" element={<Invite />} />
        <Route path="my-teams" element={<Teams />} />
        <Route path="inside" element={<Inside />} />
        <Route path="account" element={<Account />} />
        <Route path="about" element={<About />} />
        <Route path="terms" element={<Terms />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="recover" element={<Recover />} />
        <Route path="moodboard/:id" element={<Moodboard />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <App />

)
