import { useState, useEffect, useCallback } from 'react'
import { db } from "../config/firebase";
import { onValue, ref, update } from "firebase/database";
import jwt from 'jwt-decode'
//import Loggedin from '../components/Loggedin';
import { BASE_URL } from '../config/settings';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';
//import Link from '@mui/material/Link';
import { Grid, makeStyles } from '@mui/material';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
//import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
//import Feedback from '../components/Feedback'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


//import { onAuthStateChanged } from "firebase/auth";

//import { auth } from '../config/firebase';
const theme = createTheme();


const Invite = () => {
    const [open, setOpen] = useState(false);
    const [dialogname, setDialogName] = useState('')
    const [dialogkey, setDialogKey] = useState('')
    const handleClickOpen = (hash,name) => {      
        //console.log(hash,name);
        setDialogName(name)
        setDialogKey(hash)
        setOpen(true);
    };

    const handleRemove = (hash,teamhash) => {      
        //console.log("remove "+hash+' from '+teamhash);
        const apiUrl = BASE_URL+'/remove';
        //console.log(apiUrl);
        //const authToken = '';
        const requestData = {
            hash: hash,
            teamhash: teamhash,
        };

        fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json', 
            //'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify(requestData),
        })
        .then((response_remove) => {            
            if (!response_remove.ok) {
                throw new Error('Network response was not ok');
            }
            return response_remove.json();
        })
        .then((data2) => {     
            //console.log("check remove "+hash+" from "+teamhash, data2);
            if(data2 == 1){
                const objectPath = userToken.user.client_hash + '/teams/' + teamhash + '/people/' + hash;
                const database = firebase.database();
                database.ref(objectPath).remove()
                .then(() => {
                    setOpen(false);
                })
                .catch((error) => {
                    //console.error('Error removing user:', error);
                });
            }
        })
        .catch((error) => {
            //console.error('Error:', error);
        });    
    };

  const handleClose = () => {      
    setOpen(false);
  };

   const [teamname, setTeamname] = useState('')
   const [description, setDescription] = useState('')
   const handleTeamdata = (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      /*console.log({
        teamname: data.get('teamname'),
        description: data.get('description'),
        hash: teamnameUrl

      });*/
      updateTeamdata(data.get('teamname'),data.get('description'))
      
    };

    const handleNameChange = (event) => {
      setTeamname(event.target.value);
    };

    
    const handleDescriptionChange = (event) => {
      setDescription(event.target.value);
    };

    async function updateTeamdata(name, description) {
      const database = firebase.database();
      const updates = {};
      updates['name'] = name;
      updates['description'] = description;
         
                
        
      const objectPath = userToken.user.client_hash + '/teams/' + teamnameUrl;
      database.ref(objectPath).update(updates);
      
    }

    const [email, setEmail] = useState('')
    const emailHandler = (event) => {
      setEmail(event.target.value)
    }
    const handleSubmit = (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      /*console.log({
        email: data.get('email'),

      });*/
      
      //console.log("fo1")
      if(data.get('email').length > 5){
          //console.log("fo")
          inviteRequest(teamnameUrl)
      }
    };

    async function inviteRequest(teamnameUrl) {
        //console.log("trying");

      try {
        await fetch(BASE_URL + '/auth/invite', {
          method: 'POST',
          body: JSON.stringify({
            email: email,
            teamhash: teamnameUrl
          }),
        })
          .then((response) => {
              //console.log("resp from invite",response)
            if (response.ok) {

                //console.log("response 1", response.json())
                
              return response.json()
            }
            throw new Error('error response')
        }).then((resp) => {
            setEmail('');
            //console.log("resp", resp.status);
            
            const token = localStorage.getItem('token')
            const userToken = jwt(token)
            //console.log(userToken.user);
            
            
            const clienthash = userToken.user.client_hash
            const userdata = {
              name: email,
              mood: 0,
            };

          //console.log('init create!');
          const database = firebase.database();
        //console.log('go database!');
        
        const objectsRef = database.ref(clienthash+'/teams/'+teamnameUrl+'/people/'+resp.status);

        objectsRef.set(userdata)
          .then(() => {
              //navigate('/my-teams')
            //console.log('Object created successfully!');
          })
          .catch((error) => {
            //console.error('Error creating object:', error);
          });
        })
        
        
      } catch (error) {

      }
    }
    const token = localStorage.getItem("token");
    const [user, setUser] = useState()
    const userToken = jwt(token)
    const url = window.location.href;
    const urlParts = url.split("/");
    const teamnameUrl = urlParts[(urlParts.length-1)];
    const [details, setDetails] = useState([]);
    const [teamdata, setTeamdata] = useState([]);
    const base = userToken.user.client_hash+"/teams/"+teamnameUrl;
    useEffect(()=>{
        const query = ref(db, base);
        return onValue(query, (snapshot) => {
            const data = snapshot.val();
            if (snapshot.exists()) {
                setDetails(data["people"]);
                setTeamdata(data);
                //console.log(data)
                setTeamname(data["name"])
                setDescription(data["description"])
            }
        });
        
    }, [])
    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Grid item xs={50}>
          <Box

            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >



            <h1>Handle <q>{teamdata.name}</q></h1>
            <Grid container spacing={2}>
               <Grid item xs={12} md={12} sx={{ p: 0,  textAlign: 'left'}}>   
                  <h2>Team Data</h2>
                  <Box component="form" noValidate onSubmit={handleTeamdata} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                  <TextField
                                    required
                                    fullWidth
                                    id="teamname"
                                    label="Team Name"
                                    name="teamname"
                                    value={teamname} 
                                    onChange={handleNameChange}
                                  />
                            </Grid>
                            <Grid item xs={12}>
                                  <TextField
                                    
                                    fullWidth
                                    id="description"
                                    label="Description"
                                    name="description"
                                    value={description}
                                    onChange={handleDescriptionChange}
                                  />
                                  <small>The description can of the team, of what TeamTrust is used for in regards to this team, or just a info space.</small>
                            </Grid>
                        </Grid>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Update your Team Data
                        </Button>
                        
                    </Box>
               </Grid>
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={12} md={12} sx={{ p: 0,  textAlign: 'left'}}>
                    <h2 id="invite-new">Invite New Member</h2>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                  <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    onChange={emailHandler}
                                  />
                            </Grid>
                        </Grid>
                        <Button
                          type="submit"
                          fullWidth
                          id="send-invite"
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Send Invite!
                        </Button>
                        <p>Please make sure that the person you are inviting is willing to accept your invitation.</p>
                        <p>TeamTrust.me is a service for established teams.</p>
                    </Box>

                </Grid>
               <Grid item xs={12}  sx={{ p: 0,  textAlign: 'left'}}>
                  <h2>Current Members</h2>
               </Grid>
               <Grid container spacing={2}>
                    
                    {Object.keys(details).map((key, index) => {                     
                      let last_checkin = "Last checkin: Never ";
                      let faded = "faded";
                      if(details[key].mood > 0){
                        let last_date = details[key].date;
                        last_date = last_date.substring(0,16);
                        last_date = last_date.replace("T", ", ");
                        last_checkin = "Last checkin: "+last_date;
                        faded = "";
                      }
                    return (
                        <Grid item xs={6}  key={index}  className="invitedblock">
                          <div className={`invitedicon ${faded}`}><img src="/user.png" className="usericon" /></div>
                          <div className="inviteddata">
                            <p>{details[key].name}</p>                            
                            <small>{last_checkin}</small>
                            <p><Button id={details[key].name} fullWidth variant="outlined" onClick={() => handleClickOpen(key,details[key].name)} sx={{ mt: 3, mb: 2 }}>Remove member</Button></p>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Remove  "+dialogname+"?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This can not be undone.<br />
            Yes, remove the user from this team.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Nevermind</Button>
          
          <Button id="exec-remove" onClick={() => handleRemove(dialogkey,teamnameUrl)}>Remove user</Button>
            
          
        </DialogActions>
      </Dialog>
                          </div>
                          <div className="clear"></div>
                        </Grid>

                    );
                })}
                </Grid>
                
            </Grid>

      </Box>
      </Grid>
      </Container>
      </ThemeProvider>
    )
};

export default Invite;
