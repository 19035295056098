function Loggedin() {
  const hasToken = localStorage.getItem("token") !== null;

  if (!hasToken) {
    // Redirect to the login page if the user does not have a token

    window.location.href = "/login";

  }

  return (
    <div>

    </div>
    );
}

export default Loggedin;
