import { Link } from 'react-router-dom'
import Loggedin from '../components/Loggedin'
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useEffect, useCallback } from 'react'
import { onAuthStateChanged } from "firebase/auth";
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { auth } from '../config/firebase';
const theme = createTheme();
const Home = () => {
    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              const uid = user.uid;
              // ...
              //console.log("uid", uid)
            } else {
              // User is signed out
              // ...
              //console.log("user is logged out")
            }
          });

    }, [])
    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Grid item xs={50}>
          <Box

            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >

            <Hidden smDown>
        <img src="/teamtrust-logo.png" />
        </Hidden>
        <h1>How is your team is doing?</h1>
        <p>So this is our <span class="beta">beta</span> of TeamTrust.me currently for <u>web only</u>. Let's explain it in the shortest possible way:</p>

        <h3><i>TeamTrust.me is a service where teams can share their individual status expressed as simple emojis.</i></h3>
        <img src="/iphone.jpg" /><br />
        <p><small>TeamTrust.me is a service provided by Gamenudge Technologies Europe AB, Stockholm, Sweden.<br />&copy; 2024 Gamenudge Technologies Europe AB</small></p>
      </Box>

      </Grid>
      </Container>
      </ThemeProvider>
    )
};

export default Home;
