import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import jwt from 'jwt-decode'
import { BASE_URL } from '../config/settings';
import Loggedin from '../components/Loggedin';

export default function Profile() {
    Loggedin()



  const [user, setUser] = useState()

  const token = localStorage.getItem('token')

  //console.log("token",token);
  const userToken = jwt(token)
  //console.log("ERROR3")
  const getUser = useCallback(async () => {

    try {
      await fetch(BASE_URL + '/user', {
        headers: {
          Authorization: 'Bearer ' + token,

        },
      })
        .then((response) => {

          if (response.ok) {
            return response.json()
        }else{
            
        }
          throw new Error('error')
        })
        .then((data) => {
          setUser(data.status)
        })
    } catch (error) {
        //console.log("ERROR1")

        //console.log(error)
    }
  }, [])

  useEffect(() => {
    getUser()
  }, [getUser])

  return (

    <div className="profile">

      <h2>Profile</h2>
      {user && (
        <>

          <label>Email: {user.email}</label>
          <label>Client ID: {user.client_id}</label>
        </>
      )}
      <h2>User from token</h2>

      {userToken && (
        <>

          <label>Email: {userToken.user.email}</label>
          <label>Client ID: {userToken.user.client_id}</label>
          <label>Client HASH: {userToken.user.client_hash}</label>
          <label>Teams: {userToken.user.teams}</label>
          <label>User HASH: {userToken.user.user_hash}</label>
          <label>Display name: {userToken.user.display_name}</label>
          <label>FB pass: {userToken.user.fb_pass}</label>
        </>
      )}
      <Link to="/">Home</Link>
      <Link to="/logout">Log Out</Link>
    </div>
  )
}
