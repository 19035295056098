
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();
const Terms = () => {
    
    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Grid item xs={8}>
          <Box

            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              textAlign: 'left'
            }}
          >


<h1>Terms of Service</h1>
        
<h2>1. Identification of the Parties</h2>
<h3>1.1 Service Provider:</h3>
<p>Gamenudge Technologies Europe AB, Legal registration number 559388-6590 (“Provider”). The Provider is a subsidary of Curvejump E-ventures Europa AB, Legal registration number 556799-5740.</p>

<h3>1.2 User:</h3>
<p>For the purposes of these Terms of Service, the term "User" refers to any individual or entity who completes the online registration process and agrees to be bound by these Terms. The User may be referred to as "you" or "your" throughout this agreement.</p>

<h3>1.3 Agreement to Terms:</h3>
<p>By accessing or using the services provided by Gamenudge Technologies Europe AB, hereinafter referred to as the "Service," you (referred to as "User") agree to be bound by these Terms of Service (the "Terms"). If you do not agree to these Terms, please refrain from using the Service.</p>

<h3>1.4 Capacity to Contract:</h3>
<p>By using the Service, the User represents that they have the legal capacity to enter into agreements in their jurisdiction. If you are using the Service on behalf of an entity, you further represent that you have the authority to bind that entity to these Terms.</p>

<h3>1.5 Changes to Parties' Information:</h3>
<p>Both parties agree to promptly update their respective contact information provided in this section in the event of any changes during the term of this agreement. Failure to do so may impact the ability to effectively communicate and fulfill obligations under these Terms.</p>

<h2>2. Service</h2>
<h3>2.1 Description of Service</h3>
<p>The Service provided is a service that lets users create teams and team members within those teams. The team members can then set their current mood within the selected team and other team members may see that mood. The service is intended as business service for established companies and organizations. The service is in beta mode, and the provider reserves the right to change the content, usage, and availability of the service without prior notice. This can be done at any time without the user being eligible for compensation.</p>

<h3>2.2 Compliance with Applicable Laws:</h3>
<p>As a User of our Service, you agree to comply with all applicable local, state, national, and international laws and regulations. You are solely responsible for ensuring that your use of the Service is in compliance with these laws.</p>

<h3>2.3 Account Security:</h3>
<p>You are responsible for maintaining the confidentiality of any login credentials, passwords, or access codes associated with your account. You agree not to share your account credentials or permit any third party to access your account without our express permission. You must notify us immediately if you become aware of any unauthorized access to your account.</p>

<h3>2.4 User Content:</h3>
<p>If the Service allows you to submit, post, or upload content, you agree that you will not submit any content that:</p>
<ul>
<li>Is unlawful, harmful, threatening, abusive, harassing, defamatory, libelous, invasive of another's privacy, or otherwise objectionable.</li>
<li>Infringes upon the intellectual property rights of others.</li>
<li>Contains viruses, malware, or any other harmful code.</li>
<li>Violates any applicable laws or regulations.</li>
</ul>
<p>You acknowledge that we may review and remove any User Content that violates these Terms.</p>
<h3>2.5 Prohibited Activities:</h3>
<p>While using the Service, you agree not to engage in any of the following activities:</p>
<ul>
<li>Attempt to gain unauthorized access to our systems or interfere with the proper functioning of the Service.</li>
<li>Use the Service for any unlawful or fraudulent purposes.</li>
<li>Impersonate any person or entity or falsely state or misrepresent your affiliation with a person or entity.</li>
<li>Engage in any activity that could harm, disable, overburden, or otherwise impair our servers or networks.</li>
</ul>
<h3>2.6 Privacy and Data Protection:</h3>
<p>You acknowledge that your use of the Service may involve the collection and processing of personal data. You agree to our <Link href="/privacy">Privacy Policy</Link>, which governs how we collect, use, and protect your personal information.</p>
<h3>2.7 Reporting Violations:</h3>

<p>If you become aware of any violation of these Terms by other Users, you agree to promptly report it to us.</p>
<h3>2.8 Termination of Access:</h3>
<p>We reserve the right to terminate or suspend your access to the Service at our discretion if we believe you have violated these Terms or for any other reason. In such cases, you will not be entitled to any refunds or compensation.</p>
        
<h2>3. Data protection and privacy practices</h2>
<h3>3.1 Collection</h3>
<p>Data about your use of the service is collected by the provider through cookies, measurement points within the service, and through the sending and receiving of email messages.</p>
<h3>3.2 Sharing and third-party access</h3>
<p>The provider does not share any parts of your personal data directly with other parties except for the service providers we use for hosting, sending email messages, and for marketing the service. The provider does not sell any data to any third party.</p>
<h2>4. Clarification of Intellectual Property Rights and Ownership</h2>
<h3>4.1 Ownership of Intellectual Property:</h3>

<p>All intellectual property rights related to the Service, including but not limited to software, content (excluding User Content as defined in Section 2.4), trademarks, logos, and any other proprietary materials, are and shall remain the exclusive property of Gamenudge Technologies Europe AB and its licensors.</p>
<h3>4.2 User Content Ownership:</h3>
<p>By submitting, posting, or uploading User Content to the Service, you grant Gamenudge Technologies Europe AB a non-exclusive, worldwide, royalty-free, sublicensable, and transferable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such User Content on the Service for the purpose of providing and promoting the Service.</p>

<h3>4.3 User Content Representation:</h3>
<p>You represent and warrant that you have the necessary rights to grant the license described in Section 4.2 and that your User Content does not infringe upon the intellectual property rights or any other rights of any third party.</p>
<h3>4.4 Restrictions:</h3>
<p>You may not copy, modify, reproduce, distribute, create derivative works from, reverse engineer, decompile, or disassemble any part of the Service without our prior written consent.</p>

<h2>5. Licensing Terms for User-Generated Content or Data</h2>
<h3>5.1 License Granted to Service Provider:</h3>
<p>By submitting, posting, or uploading User Content to the Service, you grant Gamenudge Technologies Europe AB a non-exclusive, worldwide, royalty-free, sublicensable, and transferable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such User Content on the Service for the purpose of providing and promoting the Service.</p>

<h3>5.2 Revocability of License:</h3>
<p>You may revoke this license for specific User Content by removing or deleting it from the Service. However, please note that the revocation will not apply to User Content that others have already shared or that we have used for legitimate business purposes.</p>
<h3>5.3 User Content Representation:</h3>
<p>You represent and warrant that you have the necessary rights to grant the license described in Section 5.1 and that your User Content does not infringe upon the intellectual property rights or any other rights of any third party.</p>
<h3>5.4 Service Provider's Use of User Content:</h3>
<p>Gamenudge Technologies Europe AB may use User Content to improve the Service, develop new features, or for marketing and promotional purposes. We will not sell your User Content to third parties.</p>
<h2>6. Limitations on Provider's Liability for Service Interruptions or Data Loss</h2>
<h3>6.1 Service Interruptions:</h3>
<p>While we strive to provide a reliable and uninterrupted service, you acknowledge that the Service may be subject to occasional interruptions, downtime, or outages for various reasons, including but not limited to maintenance, technical issues, or unforeseen circumstances. You agree that we shall not be liable for any damages, losses, or inconveniences resulting from such interruptions.</p>

<h3>6.2 Data Loss:</h3>
<p>You are responsible for maintaining backups of your data and content. We do not guarantee the security or preservation of User Content, and we shall not be liable for any data loss or corruption, whether caused by our actions or factors beyond our control.</p>
<h3>6.3 Limited Liability:</h3>
<p>To the extent permitted by applicable law, our liability for any claims arising out of or related to the Service, including but not limited to service interruptions or data loss, shall be limited to the amount you have paid for the Service during the preceding 12-month period.</p>
<h3>6.4 Exclusion of Consequential Damages:</h3>
<p>In no event shall we be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, arising out of your use of the Service.</p>

<h3>6.5 Force Majeure:</h3>
<p>We shall not be liable for any failure or delay in performing our obligations under these Terms if such failure or delay is caused by circumstances beyond our reasonable control, including but not limited to acts of nature, war, terrorism, labor disputes, or technical malfunctions.</p>
<h3>6.6 User's Responsibilities:</h3>
<p>You acknowledge that the availability and functionality of the Service may depend on various factors, including your internet connection and the devices you use. You agree to use the Service responsibly and ensure the compatibility of your systems and equipment.</p>
<h3>6.7 No Warranty:</h3>
<p>The Service is provided "as is" and "as available" without warranties of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>
<h2>7. Law Governing and Jurisdiction</h2>
<h3>7.1 Choice of Law Governing the Agreement</h3>
<p>This Agreement shall be governed by and construed in accordance with the laws of Sweden, without regard to its conflict of law principles.</p>
<h3>7.2 Jurisdiction where Disputes will be Resolved</h3>
<p>Any dispute, controversy, or claim arising out of or in connection with this Agreement, including but not limited to its formation, validity, interpretation, performance, breach, or termination, shall be exclusively resolved by the courts of Sweden. The parties hereby submit to the jurisdiction of the Swedish courts and waive any objections to the venue or convenience of such courts.</p>


      </Box>

      </Grid>      
             
      </Container>
      </ThemeProvider>
    )
};

export default Terms;
