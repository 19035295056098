import { BASE_URL } from '../config/settings';
import jwt from 'jwt-decode'
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useEffect, useCallback } from 'react'

import { onAuthStateChanged } from "firebase/auth";

import { auth } from '../config/firebase';

import {  signInWithEmailAndPassword   } from 'firebase/auth';

import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const theme = createTheme();
const Account = () => {
   const [userEmail, setUserEmail] = useState('');
   const token = localStorage.getItem("token");
   const userToken = jwt(token)
  async function updateRequest(data,password) {
    
   
   //console.log("data uodate", data);
    try {
        //localStorage.removeItem("token");
      await fetch(BASE_URL + '/account/update', {
         headers: {
            Authorization: 'Bearer ' + token,
  
          },
        method: 'POST',
        body: JSON.stringify({
          username: data,
          password: password
        }),
      })
        .then((response) => {
            //console.log("response",response)
          if (response.ok) {

            const teams = JSON.parse(userToken.user.teams);
            //console.log(teams);
            updateNameInTeam(teams,userToken["user"]["user_hash"],data)
            return response.json()
          }
          throw new Error('error')
        })
       
    } catch (error) {
        //localStorage.removeItem('token')
        //console.log(error)
    }
  }


   function updateNameInTeam(teams,userhash,name){
      //console.log(teams,userhash,name);
      const database = firebase.database();
      teams.forEach(element => {
         const updates = {};
         updates['name'] = name;
         
                
        
        const objectPath = userToken.user.client_hash + '/teams/' + element + '/people/' + userToken.user.user_hash;
        database.ref(objectPath).update(updates);
      });
   }
  
  const [user, setUser] = useState()
  const getUser = useCallback(async () => {
    
    try {
      await fetch(BASE_URL + '/account/get', {
        headers: {
          Authorization: 'Bearer ' + token,

        },
      })
        .then((response) => {
          
          if (response.ok) {
            //console.log("response has OK");
            return response.json();
          }else{
            return response.status;
          }
          //throw new Error('error')
        })
        .then((data) => {
         setUserEmail(data.account.display_name)
          return setUser(data.account)
          
        })
    } catch (error) {
        //console.log("ERROR1")

        //console.log(error)
    }
  }, [])
    
    useEffect(() => {
      getUser()
    }, [getUser])
    
    
    //const display_name = user.display_name;
    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              
              
              // ...
              //console.log("uid", uid)
            } else {
              // User is signed out
              // ...
              //console.log("user is logged out")
            }
          });

    }, [])
    const handleAccount = (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      
      /*console.log({
        username: data.get('username'),
        password: data.get('password')
      });*/
      //console.log("data handle", data.get("username"));
      updateRequest(data.get("username"),data.get("password"));
      /*if(data.get('email').length > 5 && data.get('password').length >= 4 && data.get('teamname').length > 0 && data.get('terms') == 'terms'){
          registerRequest()
      }*/
    };
    

  // Define the handler to update user email
  const handleEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

   const [userPassword, setUserPassword] = useState('');
   const handlePasswordChange = (event) => {
      setUserPassword(event.target.value);
   };
   

    //const display_name = user.id
    return (
        <div>
            {user ? (
               <ThemeProvider theme={theme}>
                  <Container component="main" maxWidth="md">
                     <CssBaseline />
                     <Grid item xs={12}>
                        <Box component="form" noValidate onSubmit={handleAccount} sx={{ mt: 3 }}>
                              <h1>Account </h1>
                              <TextField id="username" name="username" label="Display Name" variant="outlined" fullWidth value={userEmail} onChange={handleEmailChange} />       
                              <p>This is the name that will be displayed in all of your teams.</p>
                              <TextField id="password" name="password" label="Password" type="password" variant="outlined" fullWidth value={userPassword} onChange={handlePasswordChange} />
                              <p>Leave empty to keep your current password</p>
                              <Button
                                    id="account_submit"
                                 type="submit"
                                 fullWidth
                                 variant="contained"
                                 sx={{ mt: 3, mb: 2 }}
                                 >Update Account</Button>
                        </Box>
                     </Grid>
                  </Container>
               </ThemeProvider>
        ) : (
          <p>Loading . . .</p>
        )}
    </div>
        
    )
};

export default Account;
