import { useEffect } from 'react'
import {  signOut } from "firebase/auth";
import {auth} from '../config/firebase';

export default function Logout() {


    localStorage.removeItem('token')
    signOut(auth).then(() => {
        // Sign-out successful.

            //console.log("Signed out successfully")
        }).catch((error) => {
        // An error happened.
        });

    useEffect(() => {

        window.location.href = '/login';
    },[])

    return (
    <></>
    )
}
