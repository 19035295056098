
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
//import Feedback from '../components/Feedback'
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme();
const Home = () => {
   
    const numbersArray = [6, 5, 4, 3, 2, 1];
    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Grid item xs={8}>
          <Box

            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >


         
         <h1>About TeamTrust.me</h1>
         <p>TeamTrust.me is a service where teams can share their individual status expressed as simple emojis.</p>
         <p>
         {numbersArray.map((number) => (                     
            <img
            key={number}  // Add a unique key prop
            src={"/icons/" + number + ".jpg"}
            id={"b" + number}
            className="abouticon" alt="icon"
          />
         ))}
         </p>
         <p>What you measure or track with these emojis is <b>entirely up to you</b>. We're just providing you with the tool to easily get a comprehensive view of your team's current status.</p>
         <p>Examples of what teams measure include: workload, team morale, how individuals perceive their progress in relation to tasks or deadlines. You've probably already thought about what you would like to measure if you've read this far.</p>
         <h2>This is a Beta</h2>
         <p>So far, TeamTrust is in Beta. This means we appreciate all the feedback we can get, and we value both patience and understanding as the service is still evolving and changing, often on a daily basis. This also means that sometimes things might hiccup and not work exactly as intended or as they did yesterday. To compensate for that, the service is free throughout the entire beta period.</p>
         
      </Box>

      </Grid>      
      
             
      </Container>
      </ThemeProvider>
    )
};

export default Home;
