import React, { useState } from 'react';
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink
} from './NavbarElements';


class Navbar extends React.Component {

    // Constructor
	/*constructor(props) {
		super(props);
        const token = localStorage.getItem('token')
        if(token == null){
            this.state = {text: 'Login', url: '/login', textTeams: '', textSignup: 'Signup'};
        }else{
            this.state = {text: 'Logout', url: '/logout', textTeams: 'My Teams', textSignup: ''};

        }
	}*/

    // Changing state

    render() {
      const token = localStorage.getItem('token')
      if(token == null){
         return (
        <Nav>
          <NavMenu>
            <NavLink to='/' activestyle="true">
              <img src="/teamtrust_icon_white_small.png" />
            </NavLink>
            <NavLink to='/login' id="login" activestyle="true">Login</NavLink>              
            <NavLink to='/register' id="register" activestyle="true">Register</NavLink>
            <NavLink to='/about' id="about" activestyle="true">About</NavLink>
          </NavMenu>
        </Nav>
        )
      }else{
        return (
          
            <Nav>
              <NavMenu>
                <NavLink to='/' activestyle="true">
                  <img src="/teamtrust_icon_white_small.png" />
                </NavLink>                
                <NavLink  to='/my-teams' id="my-teams" activestyle="true">Teams</NavLink>
                <NavLink  to='/account' id="account" activestyle="true">Account</NavLink>
                <NavLink to='/about' id="about" activestyle="true">About</NavLink>
                <NavLink to='/logout' id="logout" activestyle="true">Logout</NavLink>              
              </NavMenu>
            </Nav>
            
        )
      }
    }
}
//const Navbar = () => {


export default Navbar;
