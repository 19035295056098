import { useState, useEffect, useCallback } from 'react'
import { db } from "../config/firebase";
import { onValue, ref, update } from "firebase/database";
import jwt from 'jwt-decode'
import Loggedin from '../components/Loggedin'
import { BASE_URL } from '../config/settings';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme();

const Teams = () => {
    <Loggedin />
    const token = localStorage.getItem("token");
    const [user, setUser] = useState()
    const userToken = jwt(token)
    const getUser = useCallback(async () => {
        
      try {
        await fetch(BASE_URL + '/auth/user', {
          headers: {
            Authorization: 'Bearer ' + token,

          },
        })
          .then((response) => {

            if (response.ok) {
              return response.json()
          }
            throw new Error('error')
          })
          .then((data) => {
            setUser(data.status)
          })
      } catch (error) {
          //console.log("ERROR1")

          //console.log(error)
      }
    }, [])

    useEffect(() => {
      getUser()
    }, [getUser])
    
    function isValidJSON(str) {
        try {
          JSON.parse(str);
          return JSON.parse(str);
        } catch (e) {            
            const jsonArray = JSON.parse('["' + str + '"]');
          return jsonArray;
        }
      }
      const teams = isValidJSON(userToken.user.teams);
      
    //const teams = JSON.parse(userToken.user.teams);
    if(teams.length == 1){
        window.location.href = '/team/'+teams[0];
    }
    const hrefValue = `/team/${teams[0]}`;
    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Grid item xs={8}>
          <Box

            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >


            <img
            
            src={"/icons/6.jpg"}
            
            className="meicon" alt="icon"
          />        
         <h1>Popular you!</h1>
         <p><b>You seem to be popular!</b> You've been invited to more than one team. However, during our Beta, in order to test functionality, we are limiting the number of teams to one for a single user account. To enusure that things run smoothly, here is a link to your initial team:</p>
         <p><center><a href={hrefValue}>To my team!</a></center></p>
         
      </Box>

      </Grid>      
      
             
      </Container>
      </ThemeProvider>
    )
};

export default Teams;
