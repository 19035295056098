
import { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../config/settings';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import jwt from 'jwt-decode';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme();
export default function Login() {
    const [feedbackh5, setFeedbackh5] = useState("Have feedback?");
    const [feedback, setFeedback] = useState("");
    const [user, setUser] = useState()
    const handleSubmit = (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);


      sendFeedback(data)
      setFeedback("")
      setFeedbackh5("Thanks for the feedback!")

    };
    const handleChange = (event) => {
        setFeedback(event.target.value);
    };
    const token = localStorage.getItem('token')

    const userToken = jwt(token)

    const getUser = useCallback(async () => {

      try {
        await fetch(BASE_URL + '/user', {
          headers: {
            Authorization: 'Bearer ' + token,

          },
        })
          .then((response) => {

            if (response.ok) {
              return response.json()
          }else{              
          }
            throw new Error('error')
          })
          .then((data) => {
            setUser(data.status)
          })
      } catch (error) {
          
      }
    }, [])

    useEffect(() => {
      getUser()
    }, [getUser])

    async function sendFeedback(data) {

      try {

        await fetch('http://relay.curvejump.se/index.php/relay/postman?key=cjtestkey2022', {
          method: 'POST',
          mode: 'no-cors',
          body: JSON.stringify({
            body: "From "+userToken.user.email+": "+data.get("feedback"),
            to: 'fredrik@curvejump.se',
            subject: 'TeamTrust Feedback'
            }),

        })
          .then((response) => {              
              var fb = document.getElementByID("feedback")              
              return response.json()})
              .then(function(data){

              })



      } catch (error) {

      }
    }

    return (

          
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5" id="feedbackh5">
                {feedbackh5}
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} item id="feedbackform">
              <Grid container spacing={2}>

                <Grid style={{ width: '100%' }}  sx={{ mt: 3, mb: 0 }}>
                  <TextareaAutosize
                    required

                    minRows={7}
                    id="feedback"
                    style={{ width: '100%' }} // Set width to 100%
                    name="feedback"
                     value={feedback}
                     onChange={handleChange}
                  />
                </Grid>


              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}

              >
                Send feedback
              </Button>

            </Box>
          </Box>
          

    )
}
