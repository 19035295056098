import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../config/settings';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { auth } from '../config/firebase';
import jwt from 'jwt-decode'
const theme = createTheme();
export default function Login() {
  const navigate = useNavigate()
  const token = localStorage.getItem("token");

  if(token !== null){

      //window.location.href = "/logout";

  }
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const emailHandler = (event) => {
    setEmail(event.target.value)
  }

  const passwordHandler = (event) => {
    setPassword(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    

    loginRequest()
  };

  async function fblogin(username,password){
      try {
          signInWithEmailAndPassword(auth, username, password)
          .then((userCredential) => {
              // Signed in
              //const user = userCredential.user;
                          
              
              navigate('/my-teams')
          })
          
         .catch((error) => {
             const errorCode = error.code;
             const errorMessage = error.message;
             //console.log(errorCode, errorMessage)
         });

      } catch( error) {
          //console.log("errorx", error);
      }
  }

  async function loginRequest() {
    try {
      localStorage.removeItem("token");
      const response = await fetch(BASE_URL + '/auth/login', {
        method: 'POST',
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        //console.log("new then", data);
        if (data.status) {
          localStorage.setItem('token', data.status);
          const userToken = jwt(data.status);
          //console.log(userToken.user);
          fblogin(userToken.user.email, userToken.user.fb_pass).then(() => {
            // Additional logic after successful login
          });
        } else {
          setErrorMessage("Login failed. Please check your credentials."); // Set the error message
        }
      } else {
        setErrorMessage("Server error. Please try again later."); // Set the error message for server error
      }
    } catch (error) {
      localStorage.removeItem('token');
      //console.error(error);
      setErrorMessage("An unexpected error occurred. Please try again."); // Set the error message for unexpected error
    }
  }

  return (
      <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login to TeamTrust.me
          </Typography>
          
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={emailHandler}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={passwordHandler}
                />
              </Grid>

            </Grid>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <Button
              type="submit"
              fullWidth
              name="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
            
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/recover" variant="body2">
                    Forgot your password? 
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>

      </Container>
    </ThemeProvider>
  )
}
