const React = require('react');

const UnorderedList = function({ items }) {
  return (

    <ul>
      {items.map(function(item, i) {
        return <li key={i}>{item}</li>;
      })}
    </ul>

  );
}

export default UnorderedList;
