import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyAWzqnwcPZcnDoAXg1RKIdRojO-7KhEM3s",
    authDomain: "teamtrustprod.firebaseapp.com",
    databaseURL: "https://teamtrustprod-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "teamtrustprod",
    storageBucket: "teamtrustprod.appspot.com",
    messagingSenderId: "460648773097",
    appId: "1:460648773097:web:9be20851c022714690c778",
    measurementId: "G-5GMM54QFCN"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app);
