
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Feedback from '../components/Feedback'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useEffect, useCallback } from 'react'
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../config/firebase';

const theme = createTheme();
const Privacy = () => {
    
    
    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Grid item xs={8}>
          <Box

            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              textAlign: 'left'
            }}
          >


         
         <h1>Privacy Policy</h1>
         <p>Privacy Policy for Gamenudge Technologies Europe AB Cloud Service known as TeamTrust.me</p>
         <p>Effective Date: 2024-04-10</p>

<h2>1. Introduction</h2>

<p>Gamenudge Technologies Europe AB ("we," "us," or "our") is committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you use our cloud service ("Service").</p>

<h2>2. Information We Collect</h2>

<h3>2.1 Personal Data:</h3>
<p>We may collect personal data, such as your name, email address, and contact information when you sign up for our Service or interact with us.</p>

<h3>2.2 Usage Data:</h3>
<p>We collect information about your use of the Service, including IP addresses, device information, login details, and usage patterns. This information helps us improve the Service and provide technical support.</p>

<h3>2.3 Cookies and Tracking Technologies:</h3>
<p>We use cookies and similar tracking technologies to collect data about your interactions with our website and Service. You can manage cookie preferences through your browser settings.</p>

<h2>3. How We Use Your Information</h2>

<p>We use the collected data for the following purposes:</p>

<h3>3.1 Service Delivery:</h3>
<p>To provide and maintain the Service, fulfill your requests, and communicate with you.</p>

<h3>3.2 Improvement:</h3>
<p>To analyze usage data and improve our Service, including adding new features and functionality.</p>

<h3>3.3 Marketing:</h3>
<p>To send you promotional materials, updates, and information about our services, subject to your consent where required by law.</p>

<h2>4. Data Sharing and Disclosure</h2>

<p>We may share your data with:</p>

<h3>4.1 Third-Party Service Providers:</h3> 
<p>We may use third-party service providers to assist us in delivering and improving the Service. These providers are subject to contractual obligations to protect your data.</p>

<h3>4.2 Legal Compliance:</h3> 
<p>We may disclose data when required by law, such as in response to legal requests or to protect our rights, privacy, safety, or property.</p>

<h2>5. Data Security</h2>

<p>We employ security measures to protect your data from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</p>

<h2>6. Your Rights</h2>

<p>You have rights regarding your personal data, including the right to access, correct, or delete your data. To exercise these rights or raise concerns, please contact us using the contact information provided below.</p>

<h2>7. Children's Privacy</h2>

<p>Our Service is not intended for individuals under the age of 16. We do not knowingly collect data from children under 16. If you believe that we have inadvertently collected data from a child, please contact us to remove the information.</p>

<h2>8. Changes to this Privacy Policy</h2>

<p>We may update this Privacy Policy to reflect changes to our data practices or legal requirements. We will notify you of any significant changes through our website or email. We encourage you to review this policy periodically.</p>

<h2>9. Contact Us</h2>

<p>If you have questions or concerns about our Privacy Policy, please contact us at:</p>

<p>hello@teamtrust.me</p>



        
         
      </Box>

      </Grid>      
             
      </Container>
      </ThemeProvider>
    )
};

export default Privacy;
