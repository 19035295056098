
import { Link } from "react-router-dom";
import UnorderedList  from '../components/UnorderedList';
import LoginForm  from '../LoginForm';

const componentsMade = [
  'HelloWorld - which is the view you are seeing now!',
  'UnorderedList - which takes an array of "items" and returns a <ul> element with <li>, elements of each of those items within it',
];
const annat = [
  '1','Två'
];
const Away = () => {
  return (
      <div>
        <h1>Away!</h1>
        
        <p>But this is away.</p>
        <UnorderedList items={componentsMade} />
        <LoginForm />
        <UnorderedList items={annat} />
      </div>
  );
};

export default Away;
