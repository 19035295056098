import { useState, useEffect, useCallback, useRef } from 'react'
import { db } from "../config/firebase";
import { onValue, ref, update } from "firebase/database";
import jwt from 'jwt-decode'
import Loggedin from '../components/Loggedin';
import { BASE_URL } from '../config/settings';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Feedback from '../components/Feedback'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs from "dayjs"
import { onAuthStateChanged } from "firebase/auth";
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { auth } from '../config/firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';


const theme = createTheme();



const Team = () => {
    Loggedin()
    
    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              const uid = user.uid;
              // ...
            } else {
              // User is signed out
              
            }
          });

    }, [])
    const url = window.location.href;
    const urlParts = url.split("/");
    const teamnameUrl = urlParts[(urlParts.length-1)];
    const [details, setDetails] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [teamdata, setTeamdata] = useState([]);
    const token = localStorage.getItem("token");
    const [user, setUser] = useState()
    const userToken = jwt(token)
    const [dataLoaded, setDataLoaded] = useState(false);
    const [timeDiffClass, setTimeDiffClass] = useState('');
    const [applyAnimation, setApplyAnimation] = useState(false);
    const [dataAni, setDataAni] = useState(0);
    const [triggerd, setTriggered] = useState(false);
    
    const getUser = useCallback(async () => {

      try {
        await fetch(BASE_URL + '/auth/user', {
          headers: {
            Authorization: 'Bearer ' + token,

          },
        })
          .then((response) => {

            if (response.ok) {
                
              return response.json()
          }
            throw new Error('error')
          })
          .then((data) => {
            setUser(data.status)
          })
      } catch (error) {
            
      }
    }, [])

    useEffect(() => {
      getUser()
    }, [getUser])
    
    
    
    const base = userToken.user.client_hash+"/teams/"+teamnameUrl;
    
    function isValidJSON(str) {
        try {
          JSON.parse(str);
          return JSON.parse(str);
        } catch (e) {            
            const jsonArray = JSON.parse('["' + str + '"]');
          return jsonArray;
        }
      }
      
      const teams = isValidJSON(userToken.user.teams);
        
    if(!teams.includes(teamnameUrl)){
        window.location.href = "/";
    }
    const [prevData, setPrevData] = useState(null);
    useEffect(() => {
        const query = ref(db, base);
    



        return onValue(query, (snapshot) => {
            const newData = snapshot.val();
            if (snapshot.exists()) {
                // Check if data has changed
                if (JSON.stringify(newData) !== JSON.stringify(prevData)) {
                    
                    // Update the previous data with the new data
                    setPrevData(newData);
                    setApplyAnimation(true);
                    // Update state variables with the new data
                    setDetails(newData["people"]);
                    setTeamdata(newData);
                    setUserDetails(newData["people"][userToken["user"]["user_hash"]]);
                    setDataLoaded(true);
                }
            } else {
                // Handle if the snapshot doesn't exist
            }
        });
        /*const query = ref(db, base);
        return onValue(query, (snapshot) => {
            const data = snapshot.val();
            if (snapshot.exists()) {
                
                setDetails(data["people"]);
                setTeamdata(data);
                setUserDetails(data["people"][userToken["user"]["user_hash"]]);
                setDataLoaded(true);
            } else {
                // Handle if the snapshot doesn't exist
            }
        });*/
    }, [details]); // Run the effect whenever `details` state changes

    useEffect(() => {
        const calculateTimeDifference = () => {
          const timestamp = dayjs(userDetails.date);
          const currentTime = dayjs();
          const diffInHours = currentTime.diff(timestamp, 'hour');
    
          if (diffInHours < 4) {
            setTimeDiffClass('time-less-than-4-hours');            
          } else if (diffInHours >= 4 && diffInHours < 24) {
            setTimeDiffClass('time-4-to-24-hours');
          } else if (diffInHours >= 24 && diffInHours < 4 * 24) {
            setTimeDiffClass('time-24-to-4-days');
          } else {
            setTimeDiffClass('time-more-than-4-days');
          }
        };
    
        calculateTimeDifference();
      }, [userDetails.date]);

    /*const triggerMood = (e) => {
        e.preventDefault();
        const id = e.target.id
       
        const updates = {};
        updates['mood'] = id.substring(1);
        updates['date'] = new Date();
                
        const database = firebase.database();
        const objectPath = userToken.user.client_hash + '/teams/' + teamnameUrl + '/people/' + userToken.user.user_hash;
        database.ref(objectPath).update(updates);
 
          set_history(userToken, id)
          .then(() => {
            setTimeout(() => {
              return update(ref(db), updates);
          }, "5000");
      
    })
    .catch((error) => {
        console.error('Error:', error);
    });*/
          //return update_resp;
          
          const triggerMood = (e) => {
            e.preventDefault();
            const id = e.target.id;
           
            const database = firebase.database();
            const objectPath = userToken.user.client_hash + '/teams/' + teamnameUrl + '/people/' + userToken.user.user_hash;
            const updates = {};
            // Retrieve current value of 'triggered' and increment by 1
            database.ref(objectPath + '/triggered').once('value')
            .then((snapshot) => {
                let currentTriggeredValue = snapshot.val() || 0; // If value doesn't exist, default to 0
                
                updates['mood'] = id.substring(1);
                updates['date'] = new Date();
                updates['triggered'] = currentTriggeredValue + 1;
                
                return database.ref(objectPath).update(updates);
            })
            .then(() => {
                set_history(userToken, id)
                .then(() => {
                    setTimeout(() => {
                        return update(ref(db), updates);
                    }, 5000);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });



            
        };
        

        
  //}

  function set_history(userToken, id) {
    return new Promise((resolve, reject) => {
      const database = firebase.database();
      const history_path =
        userToken.user.client_hash + '/teams/' + teamnameUrl + '/people/' + userToken.user.user_hash +'/history/';
      const historyRef = database.ref(history_path);
  
      const currentDate = new Date();
      const history_data = JSON.stringify([{ date: currentDate, mood: id.substring(1) }]);
  
      historyRef
        .push()
        .set(history_data)
        .then(() => {
          resolve(); // Resolving the promise indicates that the operation is complete
        })
        .catch((error) => {
          reject(error); // Rejecting the promise if there is an error
        });
    });
  }

  const sortedKeys = Object.keys(details).sort((a, b) => {
    return new Date(details[b].date) - new Date(details[a].date);
  });


  var showLink = true;
  if(Object.keys(details).length >= 20){
    var showLink = false;
  }
  const numbersArray = [6, 5, 4, 3, 2, 1];
  const numbersMeaning = [  "This is not ok!",  "Something is really off!",  "Wait a minute...",  "All is fine",  "Pretty darn good",  "On top of the World!"]
  if (!dataLoaded) {
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xl">
                <CssBaseline />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h1 class="tcenter"><span className="loadtext">Loading Teamname</span></h1>
                        <p className="tcenter"><span className="loadtext"><q>Loading Description</q></span></p>
                        <p className='tcenter'><small>&nbsp;</small></p>
                        <hr />
                    </Grid>
                    <Grid item xl={12} md={12} xs={12} className="meholder">
                     <h3 className="tcenter" id="my_name"><span className='loadtext'>Loading Name</span></h3>
                     <img  src={"/icons/0.jpg"} id="mood_0" className="meicon" />                   
                     <h4 class="tcenter">&nbsp;</h4>
                  </Grid>
                  <Grid item  md={3} xs={12}></Grid>
                  {numbersArray.map((number) => (
                     <Grid item key={number} md={1} xs={2}>
                     <center><img title={numbersMeaning[(number-1)]} src={"/icons/"+number+".jpg"} id={"b"+number} className="moodicon" onClick={triggerMood} /></center>
                     
                   </Grid>
                  ))}
                  <Grid item md={12}>
                    <hr />
                    
                    <h2 class="tcenter"><span className='loadtext'>Loading the rest of the team</span></h2>
                    {showLink && (
                      <p class="tcenter"><a id="invite-link" href={`/invite/${teamnameUrl}`}>Invite more friends to the team!</a></p>
                    )}
                  </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    )
  }
  
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xl">
                <CssBaseline />
                <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h1 class="tcenter">{teamdata.name}</h1>
                  <p className="tcenter"><q>{teamdata.description}</q></p>
                  <p className='tcenter'><small><Link id="moodboard" href={`/moodboard/${teamnameUrl}`}>Moodboard</Link></small></p>
                  <hr />
                  
                  </Grid>
                  <Grid item xl={12} md={12} xs={12} className="meholder">
                     <h3 class="tcenter" id="my_name">{userDetails["name"]}</h3>
                     <img  src={"/icons/"+userDetails.mood+".jpg"} id={"mood_"+userDetails.mood} className={`meicon ${timeDiffClass}`} />                   
                     <h4 class="tcenter">{dayjs(userDetails.date).format("MMM D, HH:mm:ss")}</h4>
                  </Grid>
                  <Grid item  md={3} xs={12}></Grid>
                  {numbersArray.map((number) => (
                     <Grid item key={number} md={1} xs={2}>
                     <center><img title={numbersMeaning[(number-1)]} src={"/icons/"+number+".jpg"} id={"b"+number} className="moodicon" onClick={triggerMood} /></center>
                     
                   </Grid>
                  ))}
                  
                  
                  <Grid item md={12}>
                    <hr />
                    
                    <h2 class="tcenter">The rest of {teamdata.name}</h2>
                    {showLink && (
                      <p class="tcenter"><a id="invite-link" href={`/invite/${teamnameUrl}`}>Invite more friends to the team!</a></p>
                    )}
                  </Grid>
                  {sortedKeys.map((key, index) => {
                  //{Object.keys(details).map((key, index) => {
                    
                    if (details[key].name === userDetails["name"]) {
                        return null; // Skip rendering this iteration
                    }

                    const getTimeDiffClass = (userDate) => {
                        const timestamp = dayjs(userDate);
                        const currentTime = dayjs();
                        const diffInHours = currentTime.diff(timestamp, 'hour');

                        if (diffInHours < 4) {
                        return 'time-less-than-4-hours';
                        } else if (diffInHours >= 4 && diffInHours < 24) {
                        return 'time-4-to-24-hours';
                        } else if (diffInHours >= 24 && diffInHours < 4 * 24) {
                        return 'time-24-to-4-days';
                        } else {
                        return 'time-more-than-4-days';
                        }
                    };

                    const keyToCheck = 'date';
                    let formatted_date;

                    if (keyToCheck in details[key]) {
                        formatted_date = dayjs(details[key].date).format("MMM D, HH:mm:ss");                            
                    } else {
                        formatted_date = "Status never set :("
                    }

                    return (
                        <Grid item md={4} xs={12} key={index} sx={{ p: 1, textAlign: 'center' }}>
                        <div className={"thismembers"} data-display={details[key].name} style={{ border: '1px solid #ccc', padding: '10px' }}>
                            
                            <h4>{details[key].name}</h4>
                            <center><img src={"/icons/" + details[key].mood + ".jpg"} className={`othersmoodicon ${getTimeDiffClass(details[key].date)}`} />                            
                            <h5 className={`meicon ${getTimeDiffClass(details[key].date)}`}>
                            {formatted_date}
                            </h5></center>
                            
                        </div>
                        </Grid>
                    );
                    })}                  

                

                    
                </Grid>
                <Grid item xs={12}>
                <br /><br />
                    <Feedback />
                </Grid>
            </Container>
        </ThemeProvider>
    );
};

export default Team;
