import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../config/settings';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { auth } from '../config/firebase';
import jwt from 'jwt-decode'
const theme = createTheme();
export default function Login() {
  const navigate = useNavigate()
  const token = localStorage.getItem("token");

  if(token !== null){

      //window.location.href = "/logout";

  }
  

  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  const emailHandler = (event) => {
    setEmail(event.target.value)
  }

  
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    

    recoverRequest()
  };

    async function recoverRequest() {
    try {
      
      const response = await fetch(BASE_URL + '/auth/recover', {
        method: 'POST',
        body: JSON.stringify({
          email: email
        }),
      });
      
      if (response.ok) {
        const data = await response.json();
        
        if (data.status == 1) {          
            setEmail('')
            setErrorMessage('')
            setSuccessMessage("Recovery complete. Please check your inbox."); 
        } else {
          setErrorMessage("Recovery failed. Please check your credentials."); // Set the error message
        }
      } else {
        setErrorMessage("Server error. Please try again later."); // Set the error message for server error
      }
    } catch (error) {
      
      
      setErrorMessage("An unexpected error occurred. Please try again."); // Set the error message for unexpected error
    }
  }

  return (
      <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Recover Password
          </Typography>
          
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={emailHandler}
                />
              </Grid>
              
            </Grid>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Recover your password
            </Button>
            
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                    Login? 
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>

      </Container>
    </ThemeProvider>
  )
}
/**
 * Password recovery
 * - link to page
 * - enter email, click button
 * - if email exists send link in mail
 * - show user page that link has been sent
 * - page with hash, set new password
 * - update password 
 * - redirect to login
 * 
 */