import { useState, useEffect, useCallback } from 'react'
import { db } from "../config/firebase";
import { onValue, ref, update } from "firebase/database";
import jwt from 'jwt-decode'
import Loggedin from '../components/Loggedin';
import { BASE_URL } from '../config/settings';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Feedback from '../components/Feedback'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs from "dayjs"
import { onAuthStateChanged } from "firebase/auth";
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { auth } from '../config/firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';


const theme = createTheme();



const Moodboard = () => {
    
    
    
    const url = window.location.href;
    const urlParts = url.split("/");
    const teamnameUrl = urlParts[(urlParts.length-1)];
    const [details, setDetails] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [teamdata, setTeamdata] = useState([]);
    const token = localStorage.getItem("token");
    const [user, setUser] = useState()
    const userToken = jwt(token)
   

    

    
    const base = userToken.user.client_hash+"/teams/"+teamnameUrl;
    
    //const people = base+'/people/'+userToken.user.user_hash;
    function isValidJSON(str) {
        try {
          JSON.parse(str);
          return JSON.parse(str);
        } catch (e) {            
            const jsonArray = JSON.parse('["' + str + '"]');
          return jsonArray;
        }
      }
      const teams = isValidJSON(userToken.user.teams);
    //const teams = JSON.parse(userToken.user.teams);
    if(!teams.includes(teamnameUrl)){
        window.location.href = "/";
    }

    useEffect(() => {
        const query = ref(db, base);
        return onValue(query, (snapshot) => {
            const data = snapshot.val();
            if (snapshot.exists()) {
                setDetails(data["people"]);
                setTeamdata(data);
                setUserDetails(data["people"][userToken["user"]["user_hash"]]);                
            }
        });
    }, []);

    

    const triggerMood = (e) => {
        e.preventDefault();
        const id = e.target.id
       
        const updates = {};
        updates['mood'] = id.substring(1);
        updates['date'] = new Date();
                
        const database = firebase.database();
        const objectPath = userToken.user.client_hash + '/teams/' + teamnameUrl + '/people/' + userToken.user.user_hash;
        database.ref(objectPath).update(updates);
        
   
          
          set_history(userToken, id)
          .then(() => {
            setTimeout(() => {
              return update(ref(db), updates);
          }, "5000");
      
  })
  .catch((error) => {
    console.error('Error:', error);
  });
          //return update_resp;
          

        

        
  }

  function set_history(userToken, id) {
    return new Promise((resolve, reject) => {
      const database = firebase.database();
      const history_path =
        userToken.user.client_hash + '/teams/' + teamnameUrl + '/people/' + userToken.user.user_hash +'/history/';
      const historyRef = database.ref(history_path);
  
      const currentDate = new Date();
      const history_data = JSON.stringify([{ date: currentDate, mood: id.substring(1) }]);
  
      historyRef
        .push()
        .set(history_data)
        .then(() => {
          resolve(); // Resolving the promise indicates that the operation is complete
        })
        .catch((error) => {
          reject(error); // Rejecting the promise if there is an error
        });
    });
  }



  var showLink = true;
  if(Object.keys(details).length >= 20){
    var showLink = false;
  }
  
  const numbersArray = [6, 5, 4, 3, 2, 1];
  const numbersMeaning = [  "This is not ok!",  "Something is really off!",  "Wait a minute...",  "All is fine",  "Pretty darn good",  "On top of the World!"]
  const sortedKeys = Object.keys(details).sort((a, b) => {
    return new Date(details[b].date) - new Date(details[a].date);
  });
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xl">
                <CssBaseline />
                <Grid container sx={{  alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={12} >
                  <h1 class="tcenter" id="moodboardheader">{teamdata.name}</h1>
                  
                  
                </Grid>
                {sortedKeys.map((key, index) => {
                  //{Object.keys(details).map((key, index) => {
                    var truncatedName = details[key].name;
                    var nameMax = 15;
                    if(details[key].name.length > nameMax){
                        var truncatedName = details[key].name.substring(0,nameMax)+'...';
                    }
                    const getTimeDiffClass = (userDate) => {
                        const timestamp = dayjs(userDate);
                        const currentTime = dayjs();
                        const diffInHours = currentTime.diff(timestamp, 'hour');

                        if (diffInHours < 4) {
                        return 'time-less-than-4-hours';
                        } else if (diffInHours >= 4 && diffInHours < 24) {
                        return 'time-4-to-24-hours';
                        } else if (diffInHours >= 24 && diffInHours < 4 * 24) {
                        return 'time-24-to-4-days';
                        } else {
                        return 'time-more-than-4-days';
                        }
                    };
                    
                  return (
                      
                      <Grid item md={2} xs={12}   key={index} sx={{ p: 1,  textAlign: 'center'}}>
                        <div style={{ border: '1px solid #ccc', padding: '10px' }}>
                          <h4>{truncatedName}</h4>
                          <center><img src={"/icons/"+ details[key].mood+".jpg"} className={`othersmoodicon ${getTimeDiffClass(details[key].date)}`} />                            </center>
                          
                          <h5>{dayjs(details[key].date).format("MMM D, HH:mm:ss")}</h5>
                        </div>
                      </Grid>

                  );
                  })}                  

                <Grid item xs={12}>
                <br /><br />
                </Grid>

                    
                </Grid>
                
            </Container>
        </ThemeProvider>
    );
};

export default Moodboard;
