import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config/settings';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { auth } from '../config/firebase';
import jwt from 'jwt-decode'
const theme = createTheme();
/***
    * 1. hash password
    * 2. store user email, hashed pwd in MySQL
    * 3. generate 12 digit pass in plan text for fb auth
    * 4. update user with plain text pass
    * 5. create user auth in firebase, get uid
    * 6. update user with uid in MySQL
    * 7. generate hash for team, update user with team hash 
* 8. create team in fb, set name to name provided in register form
*/


firebase.initializeApp({
    apiKey: "AIzaSyAWzqnwcPZcnDoAXg1RKIdRojO-7KhEM3s",
    authDomain: "teamtrustprod.firebaseapp.com",
    databaseURL: "https://teamtrustprod-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "teamtrustprod",
    storageBucket: "teamtrustprod.appspot.com",
    messagingSenderId: "460648773097",
    appId: "1:460648773097:web:9be20851c022714690c778",
    measurementId: "G-5GMM54QFCN"
});




export default function Login() {
  const navigate = useNavigate()

  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [teamname, setTeamname] = useState('')
  const [termsChecked, setTermsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const handleSubmit = (event) => {
    event.preventDefault();
    if (email && password.length >= 8 && teamname && termsChecked) {
        const data = new FormData(event.currentTarget);
    

    if(data.get('email').length > 5 && data.get('password').length >= 4 && data.get('teamname').length > 0 && data.get('terms') === 'terms'){
        registerRequest()
    }
      } else {
        setErrorMessage('Please fill in all required fields and accept the Terms of Service.');
      }
    
  };
  
  /*function randomString(length){
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
      }
      return result;
  }*/

  const passwordHandler = (event) => {
    setPassword(event.target.value)
  }

  const teamnameHandler = (event) => {
    setTeamname(event.target.value)
  }

  const emailHandler = (event) => {
    setEmail(event.target.value)
  }
  

  async function registerRequest() {
      //console.log("trying");

    try {
      const response = await fetch(BASE_URL + '/register', {
        method: 'POST',
        body: JSON.stringify({
          password: password,
          email: email,
          teamname: teamname
        }),
      })
      //console.log("response", response);
      
        .then((response) => {
            //console.log("resp from register",response)
          if (response.ok === true) {
            const data = response.json();
              //console.log("response 1", data)
            return data;
          }else{
            
            throw new Error('error response')
          }
          
        })
        .then((data) => {
            //console.log("data response",data.status)
            if(data.status == "-3"){
                setErrorMessage('This email is already in use.');
            }
            //console.log("just data", data.status)
            if (data.status) {
                localStorage.setItem('token', data.status)
                return true;
        
            } else {
                //console.log("error status")
            }
      }).then(() =>{
          const token = localStorage.getItem('token')
          const userToken = jwt(token)          
          const database = firebase.database();
          signInWithEmailAndPassword(auth, userToken.user.email, userToken.user.fb_pass).then(function(firebaseUser) {
              const token = localStorage.getItem('token')
              const userToken = jwt(token)
              //console.log("token for this user: ", userToken.user);
              let teams = userToken.user.teams
              const team_obj =  JSON.parse(teams);
            //console.log("team_obj", team_obj)
                if(team_obj.length > 0){
                    teams = team_obj[0];
                }
              const clienthash = userToken.user.client_hash
            const sanitizedUserHash = userToken.user.user_hash.replace(/[.#$\/\[\]]/g, '_');
            //console.log(teams+'|'+teamname+'|'+sanitizedUserHash+'|'+email);
            const teamdata = JSON.parse('{"teams": {"'+teams+'": {"name": "'+teamname+'","state": "setup","people": {"'+sanitizedUserHash+'": {"mood": 0,"state": "active","name": "'+email+'"}}}}}');
            //console.log("teamdata", teamdata)
            const objectsRef = database.ref(clienthash);

            objectsRef.set(teamdata)
              .then(() => {
                  navigate('/my-teams')                
              })
              .catch((error) => {
                //console.error('Error creating object:', error);
              });
              //console.log("td", teamdata);
          })
          .catch(function(error) {
              //console.log("signinerror", error);
          });

      })
    } catch (error) {

    }
  }


  return (
      <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register for the TeamTrust.me Beta!
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={emailHandler}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password min 8 chars"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={passwordHandler}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="teamname"
                  label="Name your beta team"
                  type="text"
                  id="teamname"
                  autoComplete="teamname"
                  onChange={teamnameHandler}
                />
              </Grid>
              
              <Grid item xs={12}>
              {errorMessage && <div className="error-message">{errorMessage}</div>}
                <FormControlLabel
                name="terms"
                value="1"
                onChange={(e) => setTermsChecked(e.target.checked)}
                  control={<Checkbox value="terms" color="secondary" />}
                  label={
                    <Typography>
                      <Link target='_blank' href="/terms">
                        <Typography variant="inherit" color="secondary">
                        I accept the Terms of Service
                        </Typography>
                      </Link>
                      
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!email || password.length < 8 || !teamname || !termsChecked}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>

      </Container>
    </ThemeProvider>
    /*<form className="register-form" onSubmit={submitHandler}>
      <h2>Register</h2>
      <label>Name</label>
      <input type="text" value={name} onChange={nameHandler} />
      <label>Last Name</label>
      <input type="text" value={lastname} onChange={lastnameHandler} />
      <label>Username</label>
      <input type="text" value={username} onChange={usernameHandler} />
      <label>Password</label>
      <input type="password" value={password} onChange={passwordHandler} />
      <label>Email</label>
      <input type="text" value={email} onChange={emailHandler} />
      <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >Register </Button>
      <Link to="/login">Login</Link>
    </form>*/
  )
}
