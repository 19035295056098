import { Link } from 'react-router-dom'
import Loggedin from '../components/Loggedin'
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useEffect, useCallback } from 'react'
import { onAuthStateChanged } from "firebase/auth";
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { auth } from '../config/firebase';
const theme = createTheme();
const Notfound = () => {
    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Grid item xs={50}>
          <Box

            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >


        <img src="/teamtrust-logo.png" />
        <h1>404 &mdash; Ops! Not found?!</h1>
        <p>Did we mess up or did you perhaps try a page that does not exist?</p>


      </Box>
      </Grid>
      </Container>
      </ThemeProvider>
    )
};

export default Notfound;
